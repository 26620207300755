import Navbar from "../../shared/Navbar";

export default function OpportunitiesHeader() {
  return (
    <div id="wrapper" className="about-header-bg h-max pb-32">
      <div className="relative grid grid-cols-6 gap-4 place-content-evenly  flex relative z-10">
        <Navbar />
        <div className="col-start-2 col-span-4 text-center mt-48">
          <h1 className="mt-28 md:mt-0 text-5xl lg:text-8xl text-white">
            Opportunities
          </h1>
        </div>
      </div>
    </div>
  );
}
