import { AnimatedOnScroll } from "react-animated-css-onscroll";
import { useNavigate } from "react-router-dom";

export default function OpportunitiesBody() {
  const navigate = useNavigate();

  const onContactClick = () => {
    navigate("/contact");
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="py-40 bg-white relative z-10">
        <div className="mx-3.5 grid grid-cols-1 md:grid-cols-12 mb-10">
          <AnimatedOnScroll
            animationInDelay="120"
            animationInDuration="1500"
            animationIn="fadeInDown"
            className="md:hidden md:col-span-6 md:col-start-9 text-8xl text-blue-600 font-semibold ">
            <h2>
              careers<span className="text-black">.</span>
            </h2>
          </AnimatedOnScroll>

          <AnimatedOnScroll
            animationInDuration="1500"
            animationIn="fadeInDown"
            className="md:col-start-2 md:col-span-6 mt-10 md:mt-0 place-self-center text-2xl">
            <p>
              We are looking for talented individuals to join our team! Contact
              us today for more information.
            </p>
          </AnimatedOnScroll>

          <AnimatedOnScroll
            animationInDelay="120"
            animationInDuration="1500"
            animationIn="fadeInDown"
            className="hidden md:block lg:col-span-6 lg:col-start-9 text-8xl text-blue-600 font-semibold ">
            <h2>
              careers<span className="text-black">.</span>
            </h2>
          </AnimatedOnScroll>
        </div>
        <div className="mx-3.5 grid grid-cols-12 mt-10">
          <button
            onClick={() => onContactClick()}
            className="col-start-1 col-span-6 sm:col-span-4 md:col-start-2 lg:col-start-2 lg:col-span-3 xl:col-span-2 xl:col-start-2 btn btn-lg btn-outline">
            Contact Us
          </button>
        </div>
      </div>
      <div className="flex flex-col justify-center py-40 px-20 md:px-40 texture-bg">
        <AnimatedOnScroll
          animationInDelay="120"
          animationInDuration="1500"
          animationIn="fadeInDown"
          className=" mb-10 place-self-center text-5xl text-blue-600 font-semibold ">
          <h2>
            CSR Customer Service Representative
            <span className="text-black">.</span>
          </h2>
        </AnimatedOnScroll>
        <p className="text-xl">
          Harms Insurance Agency, Inc. is looking to hire a licensed insurance
          agent that will work as a customer service professional. This role
          could be part time or full time based on the individual. Please call
          the office at 580-772-2324. Positions Available in Enid or Weatherford
        </p>
      </div>

      <div className="flex flex-col justify-center py-40 px-20 md:px-40 bg-white">
        <AnimatedOnScroll
          animationInDelay="120"
          animationInDuration="1500"
          animationIn="fadeInDown"
          className=" mb-10 place-self-center text-5xl text-blue-600 font-semibold ">
          <h2>
            Licensed Agent Producer - Sales
            <span className="text-black">.</span>
          </h2>
        </AnimatedOnScroll>
        <p className="text-xl">
          Harms Insurance Agency, Inc. is interested in adding a sales producer
          to our team. If you are interested, please call the office at
          580-772-2324. Positions Available in Enid or Weatherford
        </p>
      </div>

      <div
        style={{ marginBottom: 38 + "rem" }}
        className="flex flex-col justify-center py-40 px-20 md:px-40 texture-bg">
        <AnimatedOnScroll
          animationInDelay="120"
          animationInDuration="1500"
          animationIn="fadeInDown"
          className=" mb-10 place-self-center text-5xl text-blue-600 font-semibold ">
          <h2>
            Agency Purchase Acquisition or Merger
            <span className="text-black">.</span>
          </h2>
        </AnimatedOnScroll>
        <p className="text-xl text-center">
          Are you interested in selling your agency or retiring? Please call the
          office at 580-772-2324
        </p>
      </div>
    </>
  );
}
