import Footer from "../../shared/Footer";
import OpportunitiesBody from "./opportunities-body";
import OpportunitiesHeader from "./opportunities-header";

export default function Opportunities() {
  return (
    <>
      <OpportunitiesHeader />
      <OpportunitiesBody />
      <Footer />
    </>
  );
}
